

$(document).ready(function () {
    
    //F�r tooltip
    //$('[data-toggle="tooltip"]').tooltip();
    
    /* INIT COLORBOX */
    $('a.gallery').colorbox({
        rel: 'group1',
        current: localizedStrings['colorboxCurrent'][locale],
        next: localizedStrings['nextstepLabel'][locale],
        previous: localizedStrings['previousstepLabel'][locale],
        close: localizedStrings['closeLabel'][locale],
        xhrError: localizedStrings['colorboxXhrError'][locale],
        imgError: localizedStrings['colorboxImgError'][locale],
        maxWidth: '90%',
        maxHeight: '90%'
    });

});

/* START FORM */
/* F�r att form-group ska f� r�tt class vid error */
$.validator.setDefaults({
    highlight: function (element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    }
});

/* F�r att binda custom controll till unotrubsive */
$.validator.addMethod("genericremote", function (value, element, param) {
    console.log(value);
    console.log(element.name);
    console.log(param);
    return false;
});
$.validator.unobtrusive.adapters.addBool("genericremote");

/*  F�r glyphicon animation n�r n�gon skickar */  
$('button[data-loading-text]')

    .on('click', function () {
   
    var btn = $(this)
    btn.prop('disabled', true)
    setTimeout(function () {
        btn.prop('disabled', false)
    }, 3000)
});




/* Resultat av Ajax formul�ret */
function ShowResult(info) {
    if (info == undefined) {
        $("#status").removeClass();
        $("#status").addClass("alert alert-danger");
        $("#status").html("<strong>Fel!</strong> Ett tekniskt fel intr�ffade. V�nligen f�rs�k igen eller kontakta support.<br><strong>Error!</strong> A technical error occurred. Please try again or contact support.");
        //Or redirect to error page window.location.href = "urlToRedirectPage";

    } else {
        if (info.success == true) {
            if (info.redirectPage != undefined && info.redirectPage != "") {
                window.location.href = info.redirectPage;
            } else {
                $("#target").removeClass();
                $("#target").addClass("alert alert-success");
                $("#target").html(info.message);
            }
        } else {
            if (info.redirectPage != undefined && info.redirectPage != "") {
                window.location.href = info.redirectPage;
            } else {
                $("#status").removeClass();
                $("#status").addClass("alert alert-danger");
                $("#status").html(info.message);
            }
        }

    }

}
/* END FORM */
