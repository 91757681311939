//Loacalization
var localizedStrings;
var locale = $("html").attr("lang");

(function () {

    localizedStrings = {
        cookieMessage: {
            'en-GB': 'We use cookies to track usage and preferences. If you continue, it means that you accept the use of cookies',
            'en-US': 'We use cookies to track usage and preferences. If you continue, it means that you accept the use of cookies',
            'sv-SE': 'Vi anv&auml;nder cookies f&ouml;r att ge dig b&auml;sta m&ouml;jliga upplevelse och funktion. Om du forts&auml;tter inneb&auml;r det att du accepterar att cookies anv&auml;nds.'
        },
        cookieMore: {
            'en-GB': 'Read more.',
            'en-US': 'Read more.',
            'sv-SE': 'Läs mer.'
        },
        cookieInfoPage: {
            'en-GB': '/about-cookies/',
            'en-US': '/about-cookies/.',
            'sv-SE': '/om-cookies/'
        },
        nextstepLabel: {
            'en-GB': 'Next',
            'en-US': 'Next',
            'sv-SE': 'Nästa'
        },
        previousstepLabel: {
            'en-GB': 'Previous',
            'en-US': 'Previous',
            'sv-SE': 'Föregående'
        },
        closeLabel: {
            'en-GB': 'Close',
            'en-US': 'Close',
            'sv-SE': 'Stäng'
        },
        thanksMessage: {
            'en-GB': 'Thanks for your enquiry. Please refer to #{ref} when contacting us about this enquiry.',
            'en-US': 'Thanks for your inquiry. Please refer to #{ref} when contacting us about this inquiry.',
            'sv-SE': 'Tack för din förfrågan. Var god uppge #{ref} när du kontaktar oss angående denna förfrågan.'

        },
        colorboxCurrent: {
            'en-GB': 'image {current} of {total}',
            'en-US': 'image {current} of {total}',
            'sv-SE': 'bild {current} av {total}'

        },
        colorboxXhrError: {
            'en-GB': 'This content failed to load.',
            'en-US': 'This content failed to load.',
            'sv-SE': 'Innehållet kunde inte laddas.'

        },
        colorboxImgError: {
            'en-GB': 'This image failed to load.',
            'en-US': 'This image failed to load.',
            'sv-SE': 'Bilden kunde inte laddas.'

        },
        error: {
            'en-GB': 'Error',
            'en-US': 'Error',
            'sv-SE': 'Fel'

        }
    }


    window.cookieconsent_options = {
        "message": localizedStrings['cookieMessage'][locale],
        "dismiss": "OK",
        "learnMore": localizedStrings['cookieMore'][locale],
        "link": localizedStrings['cookieInfoPage'][locale],
        "theme": "dark-bottom"
    };

})();